@function selfAdapt($px) {
  @return 100vw * ($px / 375);
}

.tags {
  .container {
    @apply flex justify-between px-[20px] pt-[25px] pb-[32px];

    .item {
      @apply flex flex-col items-center justify-center;

      .up {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        flex: none;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        .svg {
          width: 17px;
          height: 17px;
        }

        .hot {
          @apply absolute -top-[6px] -right-[12px] flex justify-center items-center;
          font-size: 8px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #FFFFFF;

          width: 25px;
          height: 12px;
          background: #FF1313;
          border-radius: 6px 6px 6px 0px;
        }
      }

      .down {
        font-size: 12px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #191919;
        line-height: 17px;
        margin-top: 13px;
      }
    }
  }
}